<template>
    <div v-if="page">
        <h2>Hotelium exclusive resort access</h2>
        <div v-if="page.doc" class="row mb-3">
            <page-title :subheading="page.doc.content"></page-title>
        </div>
        <exclusives-table :allUsers="false" :packages="page.era" :tableTitle="'My accesses'"
            :header="headers"></exclusives-table>

    </div>
</template>

<script>

import ExclusivesTable from "../components/Packages/ExclusivesTable";
import gql from "graphql-tag";
export default {
    name: "MyTravelsPage",
    components: {
        ExclusivesTable
    },
    data() {


        return {
            headers: [
                { value: "ID", text: "ID", sortable: false },
                { value: "startDate", text: "Purchase date", sortable: false },
            ]
        }
    },
    apollo: {
        page: {
            query: gql`query{era{id}}`,
            variables: {},
            update: data => data,
            result({ data }) {
                console.log(data);
            }

        }
    }
}
</script>

<style scoped></style>