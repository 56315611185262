<template>
    <div>
        <v-card>
            <v-card-title>
                <div class="card-header text-unset">{{ tableTitle }}</div>
                <v-spacer></v-spacer>
            </v-card-title>
            <v-data-table dense :headers="header" :items="packages" item-key="id">
                <template v-slot:items="props">
                    <td v-if="allUsers === true">
                        {{ props.item.user.username }}
                        <span class="font-weight-bold float-right" v-if="props.item.user.viewerLeg === 1">L</span>
                        <span class="font-weight-bold float-right" v-else-if="props.item.user.viewerLeg === 2">R</span>
                    </td>

                    <td class="date-time-width">
                        <div>{{ +new Date(props.item.id) }}</div>
                    </td>

                    <td class="date-time-width">
                        <div>{{ props.item.id | formatDate }}</div>
                    </td>
                </template>
            </v-data-table>

        </v-card>
    </div>
</template>

<script>

import gql from "graphql-tag";
export default {
    name: "ExclusivesTable",
    components: {
    },
    computed: {

    },
    props: [
        "packages",
        "tableTitle",
        "header",
        "allUsers"
    ],
    methods: {

    },

}
</script>

<style scoped>
.v-dialog__container {
    width: 100%;
}

.btn-pack-primary {
    background-color: #56aaff !important;
}

.btn-pack-secondary {
    background-color: #8bca63 !important;
}

.btn-pack-tertiary {
    background-color: #D4AF37 !important;
}

.btn-pack-dark {
    background-color: #2f485b !important;
}

.htl {
    background-color: rgba(86, 170, 255, 0.2);
}

.btc {
    background-color: rgba(255, 112, 0, 0.2);
}

.usdt {
    background-color: rgba(186, 255, 175, 0.2);
}

.price {
    justify-content: center;
    align-items: center !important;
}

.price>div>span {
    font-weight: 700;
}

.price>div {
    display: flex;
}

@media (max-width: 420px) {
    .price {
        font-size: 12px;
    }

    .price>p>span {
        display: block;
    }

    .subheader {
        height: 74px;
        padding-bottom: 8px;
    }
}
</style>